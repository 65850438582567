import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {HomeActivities, HomepageApiService} from '@odp/covid19';
import {parse} from 'date-fns';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public activities: HomeActivities[] = [];
  constructor(private titleService: Title, private homeApi: HomepageApiService) {}

  ngOnInit(): void {
    const toDate = (s: string) => parse(s, 'MMMM do, yyyy', new Date());
    this.titleService.setTitle('NCATS OpenData Portal');
    this.homeApi.getHomepageActivities().subscribe((activities) => {
      // Filter out activities with show flag set to 0
      const filteredActivities = activities.filter((activity) => activity.show !== 0);

      // Sort the remaining activities by date
      filteredActivities.sort((a, b) => toDate(b.date).getTime() - toDate(a.date).getTime());

      // Take the first 12 activities (if there are more than 12)
      this.activities = filteredActivities.slice(0, 12);
    });
  }
}
